import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import TopNavigator from './TopNavigator.js';
import BottomNavigator from './BottomNavigator.js';
import ServiceWorker from './ServiceWorker.js';
import Home from './Home.js';
import AuthScreen from './AuthScreen.js';
import AddJob from './AddJob.js';
import Jobs from './Jobs.js';
import Modal from './Modal.js';
import Notifications from './Notifications.js';

import './App.css';

const STAGES = {
  UNASSIGNED: 'unassigned',
  INPUT: "input",
  INPROGRESS: "inprogress",
  COMPLETED: "completed",
};

function App() {
  const initialState = {
    user: {
      userid: '',
      usertoken: '',
      usertype: 0,
      useremail: '',
      username: '',
    },
    jobs: [],
    notifications: [],
    modalopen: false,
    modalcontent: null,
    version: 0,
  }

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setState(state => ({ ...state, modalopen: false, modalcontent: null }));
      }
    };

    const handlePopState = () => {
      setState(state => ({ ...state, modalopen: false, modalcontent: null }));
    };

    document.addEventListener('keydown', handleEscape);
    window.addEventListener('popstate', handlePopState);

    return () => {
      document.removeEventListener('keydown', handleEscape);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [setState]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(reg => console.log('Service Worker Registered', reg))
        .catch(err => console.log('Service Worker Error', err));
    }
  }, []);


  return (
    <div className="app">
      <BrowserRouter>
        <TopNavigator state={state} setState={setState} />
        <Notifications state={state} setState={setState} />
        <ServiceWorker />
        <Routes>
          <Route path="/">
            <Route index element={<Home state={state} setState={setState} />} />
            <Route path='addjob' element={<AddJob state={state} setState={setState} />} />
            <Route path='jobs' element={<Jobs state={state} setState={setState} />} />
            <Route path='authscreen' element={<AuthScreen state={state} setState={setState} />} />
          </Route>
        </Routes>
        <Modal state={state} setState={setState} />
        <BottomNavigator state={state} setState={setState} />
      </BrowserRouter>
    </div>
  );
}

export default App;