import React from 'react';
import moment from 'moment';

import Job from './Job.js';

import './Jobs.css';

function Jobs({ state, setState }) {

    const handleClickCard = (job) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Job state={state} setState={setState} job={job} /> }));
    }

    function getProgress(stages) {
        const totalStages = Object.keys(stages).length;
        const completedStages = Object.values(stages).filter(stage => stage.completed).length;
        return (completedStages / totalStages) * 100;
    }

    return (
        <div className="jobs">
            <div className="jobs_caption">JOBS</div>

            <div className="jobs_jobs">
                {
                    state.jobs.map((job, index) => {

                        let highlighted = Object.values(job.stages).some(stage => stage.actionrequired);
                        return (
                            <div
                                key={'job' + index}
                                className={highlighted ? "jobs_jobcard jobs_jobcardhighlighted" : "jobs_jobcard"}
                                onClick={() => handleClickCard(job)}
                            >
                                <div className="jobs_jobname">Name: {job.name}</div>
                                <div className="jobs_jobprice">Price: {job.currency} {job.price}</div>
                                <div className="jobs_joblocation">Location: {job.location}</div>
                                <div className="jobs_jobworker">Worker: {job.worker}</div>
                                <div className="jobs_jobcreated">Created At: {moment.unix(job.createdts).format('DD-MMM-YYYY')}</div>
                                <div className="jobs_progressbarcontainer">
                                    <div className="jobs_progressbar" style={{ width: getProgress(job.stages) + '%' }}></div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    state.jobs.length == 0 &&
                    <div
                        className="jobs_jobcard"
                    >
                        <div className="jobs_jobname">No jobs added.</div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Jobs;