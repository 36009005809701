import React, { useState, useEffect } from 'react';

import axios from 'axios';
import configData from './Config.json';

function Notifications({ state, setState }) {
    useEffect(() => {
        const fetchNotifications = () => {
            const now = new Date();
            const hours = now.getHours();

            if (hours >= 8 && hours < 20) {
                const data = {};

                axios.post(configData.CONTROLLERURL + configData.GETNOTIFICATIONS, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    },
                }).then(res => {
                    console.log('Notifications data received.');
                    console.log(res.data);
                    if (res.data instanceof Array) {
                        setState(state => ({ ...state, notifications: res.data }));
                        if ('setAppBadge' in navigator) {
                            navigator.setAppBadge(res.data.length).catch(err => console.error("Badge failed", err));
                        }
                    }
                }).catch(() => {
                    console.error("Error fetching notifications.");
                });
            }
        };

        fetchNotifications();

        const interval = setInterval(fetchNotifications, 15 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

}

export default Notifications;