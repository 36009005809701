import React from 'react';

import './Home.css';

function Home() {
    return (
        <div className='home'>
            <div className='home_caption'>Fix everything with Quickfix.</div>
        </div>
    )
}

export default Home;