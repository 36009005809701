import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import home_optionsbutton from './Assets/topnavigator_options.png'

import './TopNavigator.css';

function TopNavigator({ state, setState }) {
  const navigation = useNavigate();

  const handleHomeButton = () => {
    navigation('/');
  }

  function About() {
    return <p className="popup_content">Quickfix is an app designed to assist in contracting works.</p>;
  }

  function Partner() {
    return <p className="popup_content">Join us to offer your services on Quickfix.</p>;
  }

  function Options() {
    const [popup, setPopup] = useState(null);

    return (
      <div className="topnvanivator_options">
        <div
          className="topnvanivator_option"
          onClick={() => setPopup(popup === "about" ? null : "about")}
        >
          About the app
        </div>
        {popup === "about" && <div className="popup"><About /></div>}

        <div
          className="topnvanivator_option"
          onClick={() => setPopup(popup === "partner" ? null : "partner")}
        >
          Partner with us
        </div>
        {popup === "partner" && <div className="popup"><Partner /></div>}
      </div>
    );
  }

  const handleOptionsButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <Options /> }));
  }

  const handleLoginButton = () => {
    navigation('/authscreen');
  }

  return (
    <div className="topnavigator">
      <div className="topnavigator_logo" onClick={handleHomeButton}>Quickfix</div>

        {
          state.user.userid &&
          <div className="topnavigator_login">Logout</div>
        }
        {
          !state.user.userid &&
          <div
            className="topnavigator_login"
            onClick={handleLoginButton}
          >
            Login
          </div>
        }
        
      <div className="topnavigator_notifications">
        <img
          className='topnavigator_optionsbutton'
          onClick={handleOptionsButton}
          src={home_optionsbutton}
          alt="Options Button"
        />
      </div>
    </div>
  );
}

export default TopNavigator;