import React, { useState, useEffect } from 'react';

import './ServiceWorker.css';

function ServiceWorker() {
    const [isServiceWorkerInstalled, setIsServiceWorkerInstalled] = useState(false);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready
                .then(() => {
                    setIsServiceWorkerInstalled(true);
                })
                .catch(() => {
                    setIsServiceWorkerInstalled(false);
                });
        }
    }, []);

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const rawData = atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; i++) {
            outputArray[i] = rawData.charCodeAt(i);
        }

        return outputArray;
    }

    const subscribeToPush = async () => {
        const registration = await navigator.serviceWorker.ready;

        const publicVapidKey = "BG8nYhO57G3Kmel11Udr9c6kxiZpKR3OPZj70H4BrUur8NO0-yoF6s99oCjsXJDi5EatrsnN-uSEzdiOP8e_Fzw";

        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
        });

        console.log("Push Subscription:", subscription);

        // Send subscription to PHP backend
        await fetch("https://propertydynamics.soukakarat.com/controller/cd/controller/save-subscription.php", {
            method: "POST",
            body: JSON.stringify(subscription),
            headers: { "Content-Type": "application/json" },
        });
    };

    const handleInstallClick = () => {
        const publicVapidKey = "BG8nYhO57G3Kmel11Udr9c6kxiZpKR3OPZj70H4BrUur8NO0-yoF6s99oCjsXJDi5EatrsnN-uSEzdiOP8e_Fzw";

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then(registration => {
                    console.log('Service Worker registered:', registration);
                    registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
                    }).then(subscription => {
                        console.log('Subscribed to Push Notifications:', subscription);
                    });
                })
                .catch(error => {
                    console.error('Service Worker registration failed:', error);
                });
        }
    };

    return (
        isServiceWorkerInstalled ? (
            <div className='serviceworker'>
                <button
                    className="serviceworker_installappbutton"
                    onClick={handleInstallClick}
                >
                    Install App
                </button>
            </div>
        ) : ''
    );
}

export default ServiceWorker;
