// src/serviceWorkerRegistration.js

export function register() {
  if (process.env.NODE_ENV === 'production') {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (navigator.serviceWorker) {
        navigator.serviceWorker
          .register(swUrl)
          .then((registration) => {
            console.log('Service Worker registered with scope: ', registration.scope);
          })
          .catch((error) => {
            console.log('Service Worker registration failed: ', error);
          });
      }
    });
  }
}

export function unregister() {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.log('Error unregistering service worker: ', error);
      });
  }
}
