import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import bottomnavigator_homebutton from './Assets/bottomnavigator_homebutton.png';
import home_addbutton from './Assets/home_addbutton.png';
import bottomnavigator_progressbutton from './Assets/bottomnavigator_progressbutton.png';

import './BottomNavigator.css';

function BottomNavigator({ state, setState }) {
  const navigation = useNavigate();

  const handleHomeButton = () => {
    navigation('/');
  }

  const handleAddJobButton = () => {
    navigation('/addjob');
  }

  const handleProgressButton = () => {
    navigation('/jobs');
  }

  return (
    <div className="bottomnavigator">
      <div className="bottomnavigator_navitem">
        <img
          className='home_homebutton'
          onClick={handleHomeButton}
          src={bottomnavigator_homebutton}
          alt="Home Button"
        />
      </div>
      <div className="bottomnavigator_navitem">
        <img
          className='home_addbutton'
          onClick={handleAddJobButton}
          src={home_addbutton}
          alt="Add Button"
        />
      </div>
      <div className="bottomnavigator_navitem">
        {state.notifications.length > 0 ? <div className='bottomnavigator_notifications'>{state.notifications.length}</div> : ''}
        <img
          className='home_jobsbutton'
          onClick={handleProgressButton}
          src={bottomnavigator_progressbutton}
          alt="Add Button"
        />
      </div>
    </div>
  );
}

export default BottomNavigator;