import React, { useEffect } from 'react';
import modal_closebutton from './Assets/modal_closebutton.png';

import './Modal.css';

//revision 20240928

const Modal = ({ state, setState }) => {
  let modalOverlayClass;
  let modalContentClass;

  switch (state.modaltype) {
    case 'large':
      modalOverlayClass = 'modal_overlay_large';
      modalContentClass = 'modal_content_large';
      break;
    case 'small':
      modalOverlayClass = 'modal_overlay_small';
      modalContentClass = 'modal_content_small';
      break;
    default:
      modalOverlayClass = 'modal_overlay_large';
      modalContentClass = 'modal_content_large';
  }

  useEffect(() => {
    if (state.modalopen) {
      document.body.classList.add('modal_removeoverflow');
    }
    else {
      document.body.classList.remove('modal_removeoverflow');
    }

    return () => {
      document.body.classList.remove('modal_removeoverflow');
    };
  }, [state.modalopen]);

  if (!state.modalopen) return null;

  const handleClose = () => {
    setState(state => ({ ...state, modalopen: false, modalcontent: null }));
  }

  return (
    <div className={modalOverlayClass} onClick={handleClose}>
      <div className={modalContentClass} onClick={(e) => e.stopPropagation()}>
        <div className='modal_title'>
          <div className='modal_titletext'>{state.modaltitle}</div>
          <img className='modal_closebutton' onClick={handleClose} src={modal_closebutton} alt="Close Button" />
        </div>
        {state.modalcontent}
      </div>
    </div >
  );
};

export default Modal;