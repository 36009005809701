import React, { useState, useEffect } from 'react';
import axios from 'axios';
import configData from './Config.json';

import moment from 'moment';

import './Job.css';

function Job({ state, setState, job }) {

    function getNextStep() {
        if (!job || !job.stages) {
            return null;
        }

        for (let key in job.stages) {
            if (job.stages[key].status == 'input') {
                return key;
            }
        }

        return null;
    }

    function renderStep(stageid) {
        switch (stageid) {
            case 'worker':
                return <Worker />;
            case 'inspection':
                return <Inspection />;
            case 'quotation':
                return <Quotation />;
            case 'scheduling':
                return <Scheduling />;
            case 'execution':
                return <Execution />;
            case 'payment':
                return <Payment />;
            case 'feedback':
                return <Feedback />;
            default:
                return <Completed />;
        }
    }

    function Worker() {
        const [workers, setWorkers] = useState([]);

        const [workerid, setWorkerid] = useState('');
        const [result, setResult] = useState('');

        useEffect(() => {
            const data = {

            };

            axios.post(configData.CONTROLLERURL + configData.GETWORKERS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                console.log('Workers data received.')
                console.log(res.data)
                if (res.data instanceof Array) {
                    setWorkers(res.data)
                }
                else {
                }
            }).catch(() => {
            }).finally(() => {
            })
        }, []);

        const handleWorkerClick = (id) => {
            setWorkerid(id);
            const selectedWorker = workers.find(worker => worker.id === id);
            setResult(selectedWorker ? selectedWorker.name : '');
        }

        const handleApprove = () => {
            if (!setWorkerid) {
                setResult('Please select worker from the list.')
                return
            }
            job.stages.worker.input.workerid = workerid;
            job.stages.worker.status = 'completed';
            job.stages.inspection.status = 'input';
            setState(state => ({ ...state, modalopen: false, modalcontent: null }));
        }

        return (
            <div className="job">
                <div className='job_title'>Worker</div>

                <div className='job_scrollable'>
                    {
                        workers.map((worker, key) => {
                            return (
                                <div
                                    key={'worker' + key}
                                    className={worker.id == workerid ? 'job_workercontainer job_workercontainerhighlighted' : 'job_workercontainer'}
                                    onClick={() => handleWorkerClick(worker.id)}
                                >
                                    <div className="worker_details">
                                        <div className='job_workerleft'>
                                            <div>{worker.name}</div>
                                            <div>{worker.title}</div>
                                            <div>{worker.experience}</div>
                                            <div>Total Jobs: {worker.totaljobs}</div>
                                        </div>
                                        <div className='job_workerright'>
                                            <img
                                                src={worker.image}
                                                alt={worker.name}
                                                className="worker_image"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {result && <div className='jobs_stageresult'>{result}</div>}
                <div className="job_buttonscontainer">
                    <button
                        className='job_button'
                        onClick={handleApprove}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    }

    function Inspection() {
        const handleApprove = () => {
            job.stages.inspection.input.approval = true;
            job.stages.inspection.status = 'completed';
            job.stages.quotation.status = 'input';
            setState(state => ({ ...state, modalopen: false, modalcontent: null }));
        }

        return (
            <div className="job">
                <div className='job_title'>Inspection</div>
                <div className='job_message'>$10 inspection will be charged covering transportation fees and consultation.</div>
                <div className="job_buttonscontainer">
                    <button
                        className='job_button'
                        onClick={handleApprove}
                    >
                        APPROVE
                    </button>
                </div>
            </div>
        );
    }

    function Quotation() {
        const [rows, setRows] = useState([]);

        useEffect(() => {
            if (job.stages.quotation.output instanceof Array) {
                setRows(job.stages.quotation.output);
            }
        }, [job.stages.quotation.output]);

        const handleApprove = () => {
            job.stages.quotation.input.approval = true;
            job.stages.quotation.status = 'completed';
            job.stages.scheduling.status = 'input';
            setState(state => ({ ...state, modalopen: false, modalcontent: null }));
        }
        const handleDecline = () => {
            job.stages.quotation.input.approval = false;
            job.stages.quotation.status = 'completed';
            setState(state => ({ ...state, modalopen: false, modalcontent: null }));
        }
        return (
            <div className="job">
                <div className='job_title'>Quotation</div>
                <table className='job_table'>
                    <thead>
                        <tr>
                            <th className='table_header'>Item</th>
                            <th className='table_header'>Quantity</th>
                            <th className='table_header'>Cost</th>
                            <th className='table_header'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td className='table_data'>{row.item}</td>
                                <td className='table_data'>{row.quantity}</td>
                                <td className='table_data'>{row.cost}</td>
                                <td className='table_data'>{row.cost * row.quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                    </tfoot>
                </table>
                <div className="job_buttonscontainer">
                    <button
                        className='job_button'
                        onClick={handleApprove}
                    >
                        Approve
                    </button>
                    <button
                        className='job_button'
                        onClick={handleDecline}
                    >
                        Decline
                    </button>
                </div>
            </div>
        );
    }

    function Scheduling() {
        const [scheduleDate, setScheduleDate] = useState('');

        const handleApprove = () => {
            if (scheduleDate) {
                const unixTimestamp = new Date(scheduleDate).getTime() / 1000; // Convert to seconds

                job.stages.scheduling.input.timestamp = unixTimestamp; // Store the Unix timestamp
                job.stages.scheduling.status = 'completed'; // Update status to completed
                job.stages.execution.status = 'input'; // Set execution status to 'wait'
                setState(state => ({ ...state, modalopen: false, modalcontent: null })); // Close modal
            } else {
                alert("Please select a date and time.");
            }
        }

        return (
            <div className="job">
                <div className='job_title'>Scheduling</div>
                <div>Schedule a date for the job:</div>
                <input
                    type="datetime-local"
                    value={scheduleDate}
                    onChange={(e) => setScheduleDate(e.target.value)}
                    className="job_datetime"
                />
                <div className="job_buttonscontainer">
                    <button
                        className='job_button'
                        onClick={handleApprove}
                    >
                        Set Schedule
                    </button>
                </div>
            </div>
        );
    }

    function Execution() {
        return (
            <div className="job">
                <div className='job_title'>Execution</div>
                <div className='job_message'>Executing the job.</div>
            </div>
        );
    }

    function Payment() {
        const handleApprove = () => {
            job.stages.payment.status = 'completed';
            setState(state => ({ ...state, modalopen: false, modalcontent: null }));
        }

        return (
            <div className="job">
                <div className='job_title'>Payment</div>
                <div className="job_buttonscontainer">
                    <button
                        className='job_button'
                        onClick={handleApprove}
                    >
                        Confirm Payment
                    </button>
                </div>
            </div>
        );
    }

    function Feedback() {
        const [rating, setRating] = useState(0);

        const handleRate = () => {
            job.stages.feeback.status = 'completed';
            setState(state => ({ ...state, modalopen: false, modalcontent: null }));
        }

        return (
            <div className="job">
                <div className="job_title">Feedback</div>
                <div className="job_feedbackstars">
                    {[1, 2, 3, 4, 5].map((star) => (
                        <span
                            key={star}
                            className={star <= rating ? "job_feedbackstar selected" : "job_feedbackstar"}
                            onClick={() => setRating(star)}
                        >
                            ★
                        </span>
                    ))}
                </div>
                <div className="job_buttonscontainer">
                    <button
                        className='job_button'
                        onClick={handleRate}
                    >
                        Submit Feedback
                    </button>
                </div>
            </div>
        );
    }

    function Completed() {
        const handleClose = () => {
            setState(state => ({ ...state, modalopen: false, modalcontent: null }));
        }

        return (
            <div className="job">
                <div className="job_title">Completed</div>
                <div className="job_message">Job completed successfully.</div>
                <div className="job_buttonscontainer">
                    <button
                        className='job_button'
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="job">
            {renderStep(getNextStep())}
        </div>
    );
}

export default Job;