import React, { useState } from 'react';
import axios from 'axios';
import configData from './Config';

import './AddJob.css';

const MAXIMAGES = 10;

const stages = {
    worker: {
        name: 'Worker',
        input: {
            workerid: ''
        },
        output: {
            workerid: ''
        },
        status: 'input'
    },
    inspection: {
        name: 'Inspection',
        input: {
            approved: false
        },
        output: {

        },
        status: 'unassigned'
    },
    quotation: {
        name: 'Quotation',
        input: {
            approved: false
        },
        output: {

        },
        status: 'unassigned'
    },
    scheduling: {
        name: 'Scheduling',
        input: {
            timestamp: ''
        },
        output: {

        },
        status: 'unassigned'
    },
    execution: {
        name: 'Execution',
        input: {},
        output: {

        },
        status: 'unassigned'
    },
    payment: {
        name: 'Payment',
        input: {

        },
        output: {
            completed: false
        },
        status: 'unassigned'
    },
    feedback: {
        name: 'Feedback',
        input: {
            rating: 0
        },
        output: {
            rating: 0
        },
        status: 'unassigned'
    }
};

const compressImage = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 800;
            const maxHeight = 800;

            let { width, height } = img;
            if (width > maxWidth || height > maxHeight) {
                const scale = Math.min(maxWidth / width, maxHeight / height);
                width = width * scale;
                height = height * scale;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.7);
            callback(compressedDataUrl);
        };
    };
    reader.readAsDataURL(file);
};

function AddJob({ state, setState }) {
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');

    const [images, setImages] = useState([]);
    const [imagespreview, setImagespreview] = useState([]);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [result, setResult] = useState('');

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);

        if (files.length > MAXIMAGES) {
            alert("You can only upload up to " + MAXIMAGES + " images.");
            return;
        }

        const selectedFiles = [...event.target.files];
        const compressedImages = [];
        const imagePreviewUrls = [];

        selectedFiles.forEach((file, index) => {
            compressImage(file, (compressedImageUrl) => {
                compressedImages.push(compressedImageUrl);
                imagePreviewUrls.push(compressedImageUrl);

                if (compressedImages.length === selectedFiles.length) {
                    setImages(compressedImages);
                    setImagespreview(imagePreviewUrls);
                }
            });
        });
    };

    const handleSubmit = () => {
        console.log('Submitting job');

        let jobs = [...state.jobs];
        let now = Math.floor(Date.now() / 1000);

        const newJob = {
            id: jobs.length + 1,
            type: type,
            name: 'New Job',
            image: images,
            price: 0,
            currency: '',
            stages: stages,
            createdts: now,
            updatedts: now,
            location: '',
            worker: ''
        };

        jobs.push(newJob);
        console.log(jobs)
        setState(prevState => ({ ...prevState, jobs }));
        return
        setLoading(true);

        const formData = new FormData();
        formData.append('userid', state.user.userid);
        formData.append('type', type);
        formData.append('images', images);

        axios.post(configData.CONTROLLERURL + configData.ADDJOB, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken,
            },
        }).then((res) => {
            console.log('Received add job data');
            console.log(res.data)

            if (res.data instanceof Object) {
                if (res.data.code == 1) {

                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error')
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
            setProgress(100);
        });
    };

    return (
        <div className="addjob">
            <div className="addjob_caption">ADD JOB</div>

            <div className="addjob_form">
                <select value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="">Select a job type</option>
                    <option value="mechanical">Mechanical</option>
                    <option value="electrical">Electrical</option>
                    <option value="plumbing">Plumbing</option>
                    <option value="civil">Civil</option>
                    <option value="cleaning">Cleaning</option>
                </select>

                <textarea
                    className="addjob_description"
                    placeholder="Enter job description..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                ></textarea>

                <input
                    className='addjob_images'
                    type="file"
                    multiple
                    onChange={handleImageChange}
                />

                {
                    imagespreview.length > 0 &&
                    <div className="addjob_imagespreview">
                        {
                            imagespreview.map((preview, index) => (
                                <img
                                    key={'image' + index}
                                    src={preview}
                                    alt={`Preview ${index + 1}`}
                                    className="addjob_imagepreview"
                                />
                            ))
                        }
                    </div>
                }

                <button
                    className='addjob_submitbutton'
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? 'Adding...' : 'Add Job'}
                </button>
            </div>
        </div>
    );
}

export default AddJob;